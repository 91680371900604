import { inject } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  Router,
  type CanActivateFn,
} from '@angular/router'
import * as fromSelectedMatch from '@app/pages/match-calendar/store/selected-match'
import { Store } from '@ngrx/store'

export const tokenGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot
): true | Promise<boolean> => {
  const router = inject(Router);
  const token = route.queryParams['token'];
  const store = inject(Store<fromSelectedMatch.SelectedMatchState>);
  const tempToken = 'YiUOykO5tHRJ9soIlKmopCpyky8IR3';

  if (token && token === tempToken) {
    store.dispatch(fromSelectedMatch.editSelectedMatchView({ editView: true }));

    return true;
  } else if (token && token !== tempToken) {
    store.dispatch(
      fromSelectedMatch.editSelectedMatchView({ editView: false })
    );

    return router.navigate(['/main']);
  }

  store.dispatch(fromSelectedMatch.editSelectedMatchView({ editView: false }));

  return true;
};
