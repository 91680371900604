import { registerLocaleData } from '@angular/common'
import {
  HttpClientModule, provideHttpClient
} from '@angular/common/http'
import localeEs from '@angular/common/locales/es-MX'
import localeEsExtra from '@angular/common/locales/extra/es-MX'
import {
  ApplicationConfig,
  LOCALE_ID,
  Provider,
  isDevMode
} from '@angular/core'
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { provideRouter } from '@angular/router'
import { provideEffects } from '@ngrx/effects'
import { provideState, provideStore } from '@ngrx/store'
import { provideStoreDevtools } from '@ngrx/store-devtools'
import { routes } from './app.routes'
import { effects } from './store'
import { matchesReducers } from './store/matches'
import { positionsTableReducers } from './store/positions-table'

registerLocaleData(localeEs, localeEsExtra)

export const provideLocaleConfig = (): Provider[] => {
  return [
    {
      provide: LOCALE_ID,
      useValue: 'es-MX'
    },
    {
      provide: DateAdapter,
      useClass: NativeDateAdapter,
      deps: [MAT_DATE_LOCALE]
    }
  ]
}

export const appConfig: ApplicationConfig = {
  providers: [
    HttpClientModule,
    provideLocaleConfig(),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(),
    provideStore({}),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !isDevMode(),
      autoPause: true,
      trace: false,
      traceLimit: 75,
    }),
    provideState('matches', matchesReducers),
    provideState('positionsTable', positionsTableReducers),
    provideEffects([...effects]),
  ]
};
